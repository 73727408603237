@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'NotoSans';
  src: url('./assets/fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Black';
  src: url('./assets/fonts/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('./assets/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-ExtraBold';
  src: url('./assets/fonts/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-ExtraCondensed-Black';
  src: url('./assets/fonts/NotoSans_ExtraCondensed-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: condensed;
}

html,
body,
#root {
  height: 100%;
}

/* 
----------------------------------------------------------
  Toast
----------------------------------------------------------
*/
/* .Toastify__toast--success {
  background-color: #0066b1 !important;
}

.Toastify__toast--error {
  background-color: #d93400 !important;
} */
/* 
----------------------------------------------------------
  Toast
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Default page print style
----------------------------------------------------------
*/
@media print {
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  @page {
    size: A4 portrait;
    margin-right: 0cm;
    margin-bottom: 0.85cm;
    margin-left: 0cm;
    margin-top: 0.85cm;
    padding: 0cm;
  }
}
/* 
----------------------------------------------------------
  Default page print style
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Sidebar
----------------------------------------------------------
*/
#sidebar-menu::-webkit-scrollbar {
  width: 5px;
}

#sidebar-menu::-webkit-scrollbar-track {
  padding: 5px 2px 5px 0px;
}

#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #00c8bb;
  border-radius: 20px;
}
/* 
----------------------------------------------------------
  Sidebar
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Main scrollbar
----------------------------------------------------------
*/
#scrollable-parent::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

#scrollable-parent::-webkit-scrollbar-track {
  background: #f5f5f5; /* color of the tracking area */
  padding: 5px 2px 5px 0px;
}

#scrollable-parent::-webkit-scrollbar-thumb {
  background-color: #00c8bb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
/* 
----------------------------------------------------------
  Main scrollbar
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Table scrollbar
----------------------------------------------------------
*/
#scrollable-table::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
  height: 5px; /* Thickness of horizontal scrollbar */
}

#scrollable-table::-webkit-scrollbar:horizontal {
  height: 5px; /* Set same thickness as vertical scrollbar */
}

#scrollable-table::-webkit-scrollbar-track {
  background: #dddddd; /* color of the tracking area */
  padding: 2px 0; /* Adds a small separation inside the scrollbar track */
}

#scrollable-table::-webkit-scrollbar-thumb {
  background-color: #00c8bb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
/* 
----------------------------------------------------------
  Table scrollbar
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Title gradient
----------------------------------------------------------
*/
.header {
  background: linear-gradient(
    -45deg,
    white 0%,
    white 34%,
    #00c8bb 34%,
    #00c8bb 36%,
    #0b1f92 36%,
    #0b1f92 38%,
    white 38%,
    white 40%,
    #00c8bb 40%,
    #00c8bb 100%
  );
}

/* Extra small devices (phones, 640px and down) */
@media only screen and (max-width: 640px) {
}

/* Small devices (portrait tablets and large phones, 640px and up) */
@media only screen and (min-width: 640px) {
  .header {
    background: linear-gradient(
      -45deg,
      white 0%,
      white 44%,
      #00c8bb 44%,
      #00c8bb 45.5%,
      #0b1f92 45.5%,
      #0b1f92 47%,
      white 47%,
      white 48.5%,
      #00c8bb 48.5%,
      #00c8bb 100%
    );
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  .header {
    background: linear-gradient(
      -45deg,
      white 0%,
      white 60%,
      #00c8bb 60%,
      #00c8bb 61%,
      #0b1f92 61%,
      #0b1f92 62%,
      white 62%,
      white 63%,
      #00c8bb 63%,
      #00c8bb 100%
    );
  }
}

/* Large devices (laptops/desktops, 1280px and up) */
@media only screen and (min-width: 1280px) {
  .header {
    background: linear-gradient(
      -45deg,
      white 0%,
      white 73.5%,
      #00c8bb 73.5%,
      #00c8bb 74%,
      #0b1f92 74%,
      #0b1f92 74.5%,
      white 74.5%,
      white 75%,
      #00c8bb 75%,
      #00c8bb 100%
    );
  }
}

/* Large devices (laptops/desktops, 1536px and up) */
@media only screen and (min-width: 1536px) {
  .header {
    background: linear-gradient(
      -45deg,
      white 0%,
      white 80%,
      #00c8bb 80%,
      #00c8bb 80.5%,
      #0b1f92 80.5%,
      #0b1f92 81%,
      white 81%,
      white 81.5%,
      #00c8bb 81.5%,
      #00c8bb 100%
    );
  }
}
/* 
----------------------------------------------------------
  Title gradient
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Radio buttons
----------------------------------------------------------
*/
input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #0b1f92;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #0b1f92;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type='radio']:disabled {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #fecaca;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #dc2626;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: not-allowed;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00c8bb;
}

input[type='radio']:checked::before {
  transform: scale(1);
}
/* 
----------------------------------------------------------
  Radio buttons
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Custom range input
----------------------------------------------------------
*/
.range:active::-webkit-slider-thumb {
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.4);
}

.range::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 94px;
  background: red;
  border-radius: 0.75rem;
  cursor: grab;
  margin-top: -1px;
}

.range-thumb {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 100px;
  border-radius: 0.75rem;
  cursor: grab;
}
/* 
----------------------------------------------------------
  Custom range input
----------------------------------------------------------
*/
